import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./NewsListing.scss";
import "./TeamListing.scss";
import { GetOffices, AllTeams } from "../../queries/common_use_query";
import { TeamBaseURL } from "../common/site/utils";
import { Link, graphql, useStaticQuery } from "gatsby";
import GetGGFXImage from "../common/site/get-ggfx-image";
import Select from "react-select";
import $ from "jquery";
import { sort } from "../utils/utils";
import noImg from "../../images/no-image.png"

const postsPerPage = 12;
let arrayForHoldingPosts = [];

function NewsListing(props) {
  const data = useStaticQuery(graphql`
    query GetAllTeams {
      glstrapi {
        teams {
          id
          Name
          URL
          Designation
          sort_order
          Image {
            url
            alternativeText
          }
          Select_Office {
            id
            Name
            URL
          }
          imagetransforms
        }
        offices {
          id
          Name
          URL
        }
      }
    }
  `);

  const [newscategory, setNewscat] = useState("");
  const [mobileonly, setMobileonly] = useState(false);
  const [listTeams, setListTeams] = useState([]);

  const officesData = data?.glstrapi?.offices;
  const teamsData = data?.glstrapi?.teams;
  const sortedTeams = teamsData?.sort((a, b) => sort(a.sort_order, b.sort_order));

  useEffect(() => {
    setListTeams(sortedTeams);
  }, [data]);

  const [teamsToShow, setPostsToShow] = useState([]);
  const [next, setNext] = useState(9);

  useEffect(() => {
    arrayForHoldingPosts = [];
    if ($(window).width() < 767) {
      setMobileonly(true);
    }
  }, []);

  useEffect(() => {
    arrayForHoldingPosts = [];
    let filteredTeams = sortedTeams;
    if (newscategory) {
      setPostsToShow([]);
      filteredTeams = sortedTeams.filter((obj) => obj.Select_Office?.id === newscategory);
    }
  
    setListTeams(filteredTeams);
    setPostsToShow(filteredTeams.slice(0, postsPerPage));
    setNext(postsPerPage);
  }, [newscategory, data]);

  const loopWithSlice = (start, end) => {
    const slicedPosts = listTeams.slice(start, end);
    arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts];
    setPostsToShow(arrayForHoldingPosts);
  };

  const handleShowMorePosts = () => {
    const newNext = next + postsPerPage;
    setPostsToShow(listTeams.slice(0, newNext));
    setNext(newNext);
  };

  useEffect(() => {
  if (listTeams.length > 0) {
    loopWithSlice(0, postsPerPage);
  }
}, [listTeams]);

  const ImageRenderList = ({ news, processedImages }) => {
    return (
      news.Image ? <GetGGFXImage
        imagename={"teams.Image.tileimg"}
        imagesource={news.Image}
        fallbackalt={news.Name + " - " + news.Designation}
        imagetransformresult={processedImages}
        id={news.id}
      /> : <img src={noImg} alt={news.Name + " - " + news.Designation} />
    );
  };
  var selectvalues = [];
  selectvalues.push({ value: "", label: "All Offices" });
  {
    officesData && officesData.map((val) => selectvalues.push({ value: val.id, label: val.Name }));
  }
  return (
    <section className="section-p120 news-listing team-listing">
      <Container>
        <div className="list-head">
          <h1>{props.title}</h1>
          <div className="news-cat">
            {mobileonly ? (
              <Select
                options={selectvalues}
                values={[]}
                isSearchable={false}
                classNamePrefix={"select-opt"}
                className="select-control"
                defaultValue={{
                  label: selectvalues.length > 0 && selectvalues[0].label,
                  value: selectvalues.length > 0 && selectvalues[0].value,
                }}
                onChange={(e) => setNewscat(e.value)}
              />
            ) : (
              <ul>
                <li>
                  <a
                    href="javascript:;"
                    onClick={() => setNewscat("")}
                    className={!newscategory ? "active" : ""}
                  >
                    All Offices
                  </a>
                </li>
                {officesData &&
                  officesData.map((newscat) => {
                    return (
                      <>
                        <li>
                          <a
                            href="javascript:;"
                            className={newscat.id == newscategory ? "active" : ""}
                            onClick={() => setNewscat(newscat.id)}
                          >
                            {newscat.id == "62bc54a1d609fc1ea5c9a5d3" ? "Crouch End Office" : newscat.Name}
                          </a>
                        </li>
                      </>
                    );
                  })}
              </ul>
            )}
          </div>
        </div>
        <div className="news-listing-wrapper">
          <Row>
            {teamsToShow &&
              teamsToShow.map((news, index) => {
                let processedImages = JSON.stringify({});
                if (news?.imagetransforms?.Image_Transforms) {
                  processedImages = news.imagetransforms.Image_Transforms;
                }
                return (
                  <>
                    <Col xs={6} md={`6`} lg={`3`}>
                      <div className="news-item-wrap">
                        <div className="img-zoom rounded">
                          <Link to={TeamBaseURL + news.URL + "/ "}>
                            <ImageRenderList processedImages={processedImages} news={news} />
                          </Link>
                        </div>
                        <div className="news-title">
                          <Link to={TeamBaseURL + news.URL + "/ "}>{news.Name}</Link>
                        </div>
                        <div className="news-date">{news.Designation}</div>
                      </div>
                    </Col>
                  </>
                );
              })}
            {teamsToShow && teamsToShow.length > 11 && teamsToShow.length >= next && (
              <a onClick={handleShowMorePosts} class="btn btn-dark load-more">
                Load More
              </a>
            )}
          </Row>
        </div>
      </Container>
    </section>
  );
}

export default NewsListing;
